<template>
    <div>
        <el-row>
            <el-col :span="24">
                <el-form :inline="true">
                    <el-form-item label="关键字:">
                        <el-input v-model="filters.keyWord" placeholder="请输入关键字" clearable>
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <toolbar
                            :buttonList="buttonList"
                            @callFunction="callFunction"
                        ></toolbar>
                    </el-form-item>
                </el-form>
                </el-col>
        </el-row>
        <!-- 表格主体 -->
        <el-table
            border
            highlight-current-row
            :data="tableData"
            v-loading="listLoading"
            @current-change='currentChange'
            :default-sort = "{prop: 'Name', order: 'descending'}"
            ref="multipleTable"
            style="width: 100%">
            <el-table-column type='index' label='序号' align="center" width='60'></el-table-column>
            <!-- <el-table-column type="selection" width="50" align="center"></el-table-column> -->
            <el-table-column prop="Name" label="批次名称" align="center"></el-table-column>
            <el-table-column prop="CandidatesNumber" label="考生数量" align="center">
                <template slot-scope='scope'>
                    <el-button @click="toNowBatchFun(scope.row)" type="text">{{scope.row.CandidatesNumber}}</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="CreateTime" label="创建日期" align="center"></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="this.pages.pageIndex"
            :page-sizes="[20,40,80,100]"
            :page-size="this.pages.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="this.pages.dataCount"
            class='pageBar'
        >
        </el-pagination>
    </div>
</template>
<script>
import Toolbar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import { getExamBatchPopulationList,deleteExaminationBatch } from '@/api/api'
import util from "../../../util/date";
export default {
    components: { Toolbar },
    data(){
        return {
            filters:{
                keyWord:'',
            },
            // 分页
            pages:{
                pageIndex:1,
                pageSize:40,
                dataCount:0
            },
            needData: '',
            // 表格数据
            tableData:[],
            buttonList:[],
            listLoading:false,
        }
    },
    methods:{
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 查询
        getExamBatchPopulationList(){
            this.getTableData()
        },
        // 获取表格数据
        getTableData(){
            var params = {
                processName: this.filters.keyWord ? this.filters.keyWord : null,
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            }
            this.listLoading = true
            getExamBatchPopulationList(params).then(res => {
                if(res.data.Success){
                    this.tableData = res.data.Response.Data
                    this.pages.dataCount = res.data.Response.DataCount
                }else{
                    this.$message({
                        message:res.data.Message,
                        type:'error'
                    })
                }
                this.listLoading = false
            })
        },
        // 分页处理
        handleSizeChange(value){
            this.pages.pageSize = value
            this.getTableData()
        },
        handleCurrentChange(value){
            this.pages.pageIndex = value
            this.getTableData()
        },
        // 点击table某一行
        currentChange(val) {
            this.needData = val
        },
        // 删除
        handleDel() {
            if(this.needData) {
                this.$confirm(`确定将永久删除？`,"提示",{
                }).then(() => {
                    var params = {
                        ID: this.needData.ID
                    }
                    deleteExaminationBatch(params).then((res) => {
                        var result = res.data
                        if(result.Success) {
                            this.$message.success(result.Message)
                            this.getTableData()
                        } else {
                            this.$message.error(result.Message)
                        }
                    })
                })
                .catch((err) => {

                })
            } else {
                this.$message.warning("请选择要删除的数据！")
            }
        },
        toNowBatchFun(row) {
            this.$router.replace({path:'/StudentExaminee/ExamEnroll',query:{batchId:row.ID}})
        }
    },
    mounted(){
        this.getTableData()
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>
    
</style>